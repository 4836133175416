export const WORKING_NETWORK = "mainnet";

// const MY_ACCOUNT = "0.0.49054569";

export const HL_CONTRACT_ID = ""
export const HL_TOKEN_ID = "0.0.1890727";

export const HLEG_CONTRACT_ID = "";
export const HLEG_TOKEN_ID = "";

export const HL_contractId = "";
export const HL_tokenId = "";
export const HLEG_contractId = "";
export const HLEG_tokenId = "";

export const MAX_GAS = 150000;

export const MD_BU =
  "https://hederalegacy.com/Essentials/Essentials_";
export const MD_EXT = ".json";

export const MAINNET_MIRROR_NODE_BASE_URL =
  "https://mainnet-public.mirrornode.hedera.com";
export const TOKENS = "/api/v1/tokens/";
export const ACCOUNTS_NFTS =
  "/api/v1/accounts/{accountId}/nfts?token.id={tokenId}&limit={limit}&order=asc&serialnumber=gte:{gte}";
export const ACCOUNT_TOKENS =
  "/api/v1/accounts/{accountId}/tokens?token.id={tokenId}&limit=1&order=asc";
export const BALANCES =
  "/api/v1/balances?account.id={accountId}&order=asc&limit=1";
export const CONTRACT_STATE =
  "/api/v1/contracts/{contractId}/state?slot=gte:{gte}&order=asc&limit={limit}";
export const TOKENS_NFTS = "/api/v1/tokens/{tokenId}/nfts/{serialNumber}";

export const IPFS_URL = "https://ipfs.io/ipfs/";

export const HL_CONTRACT_SLOT = {
  MAX_SUPPLY: 3,
  COST: 4,
  TOKEN_ADDRESS: 7,
};

export const HLEG_CONTRACT_SLOT = {
  PERFORMANCE_FEE: 7,
  TOTAL_STAKED: 8,
  SMALL: 9,
  MEDIUM: 10,
  LARGE: 11,
  SMALL_REWARD: 12,
  MEDIUM_REWARD: 13,
  LARGE_REWARD: 14,
  STAKE_INFOS: 15,
  STAKE_BY_OWNER: 16,
};

export const NOT_AVAILABLE = "NOT AVAILABLE";
