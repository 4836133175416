import React, { Children, useMemo, useState } from "react";
import { useEffect } from "react";
import { HashConnect, MessageTypes } from "hashconnect";
import { WORKING_NETWORK } from "../constants";
import {
  AccountId,
  ContractExecuteTransaction,
  ContractCallQuery,
  ContractFunctionParameters,
  Hbar,
  TransactionResponse,
  TransactionReceipt,
  TransactionRecord,
  TransactionId,
} from "@hashgraph/sdk";

export const defaultMarket = {
  accountId: null,
  hashconnect: null,
  topic: null,
  pairingString: null,
  pairingData: null,
  availableExtension: null,
  state: null,
  provider: null,
  signer: null,
  initHashconnect: () => {},
  connectToExtension: () => {},
  sendTransaction: () => {},
  requestAccountInfo: () => {},
  disconnect: () => {},
  clearPairings: () => {},
};

export const HashconnectProvider = ({ children }) => {
  const [accountId, setAccountId] = useState(null);
  const [hashconnect, setHashconnect] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [topic, setTopic] = useState(null);
  const [pairingString, setPairingString] = useState(null);
  const [pairingData, setPairingData] = useState(null);
  const [availableExtension, setAvailableExtension] = useState(null);
  const [state, setState] = useState(null);

  const connectToExtension = async () => {
    //this will automatically pop up a pairing request in the HashPack extension
    hashconnect?.connectToLocalWallet();
    // hashconnect?.connectToLocalWallet(pairingString, extensionMetadata);
  };

  const sendTransaction = async (
    trans,
    acctToSign,
    return_trans = false,
    hideNfts = false,
    getRecord = false
  ) => {
    // MessageTypes.Transaction
    const transaction = {
      topic: topic,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
        hideNft: hideNfts,
        getRecord: getRecord,
      },
    };
    return await hashconnect?.sendTransaction(topic, transaction);
  };

  const requestAccountInfo = async () => {
    //MessageTypes.AdditionalAccountRequest
    let request = {
      topic: topic,
      network: WORKING_NETWORK,
      multiAccount: true,
    };

    await hashconnect?.requestAdditionalAccounts(topic, request);
  };

  const disconnect = () => {
    hashconnect?.disconnect(pairingData?.topic);
    setPairingData(null);
    // setState(null);
  };

  const clearPairings = () => {
    hashconnect?.clearConnectionsAndData();
    setPairingData(null);
  };

  const initHashconnect = async () => {
    const setUpHashConnectEvents = (newHC) => {
      //This is fired when a extension is found
      newHC.foundExtensionEvent.on((data) => {
        console.log("Found extension", data);
        setAvailableExtension(data);
      });

      //This is fired when a wallet approves a pairing
      newHC.pairingEvent.on((data) => {
        console.log("Paired with wallet", data);
        setPairingData(data.pairingData);
      });

      //This is fired when HashConnect loses connection, pairs successfully, or is starting connection
      newHC.connectionStatusChangeEvent.on((state) => {
        console.log("hashconnect state change event", state);
        setState(state);
      });

      newHC.acknowledgeMessageEvent.on((data) => {
        //do something with acknowledge response data
      });
    };

    const appMetadata = {
      name: "HEDERA LEGACY",
      description: "",
      icon: "../../public/H.png",
      url: "https://hederalegacy.com",
    };

    //create the hashconnect instance
    const newHC = new HashConnect(true);

    //register events
    setUpHashConnectEvents(newHC);

    //initialize and use returned data
    let initData = await newHC.init(appMetadata, WORKING_NETWORK, false);

    setTopic(initData.topic);
    setPairingString(initData.pairingString);

    //Saved pairings will return here, generally you will only have one unless you are doing something advanced
    setPairingData(initData.savedPairings[0]);
    console.log("----------------");
    console.log(initData.encryptionKey);
    // console.log(signer);
    // console.log(signer.call);
    newHC.transactionResolver = (txRes) => {
      console.log("myowntransactionresolver");
      console.log("Txn Response", txRes);
      console.log("Txn Receipt", TransactionReceipt.fromBytes(txRes.receipt));
    };
    setHashconnect(newHC);
  };
  useEffect(() => {
    initHashconnect();
  }, []);
  useEffect(() => {
    const account = pairingData?.accountIds[0];
    setAccountId(account);
    const hcProvider = hashconnect?.getProvider(
      WORKING_NETWORK,
      topic,
      account
    );
    const hcSigner = hashconnect?.getSigner(hcProvider);
    // console.log(hcSigner?.call);
    setProvider(hcProvider);
    setSigner(hcSigner);
  }, [hashconnect, topic, pairingData]);

  return (
    <HashconnectContext.Provider
      value={{
        accountId,
        hashconnect,
        topic,
        pairingString,
        pairingData,
        availableExtension,
        state,
        provider,
        signer,
        initHashconnect,
        connectToExtension,
        sendTransaction,
        requestAccountInfo,
        disconnect,
        clearPairings,
      }}
    >
      {children}
    </HashconnectContext.Provider>
  );
};

const HashconnectContext = React.createContext(defaultMarket);

export const useHashconnectProvider = () => {
  return React.useContext(HashconnectContext);
};
