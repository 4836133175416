import { Box } from "@mui/joy";
import { useEffect, useState } from "react";

function CountDown() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2023-02-09T21:00:00+01:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <div className="App">
      {timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
          <p className="subtitle">Expect us in: {timeLeft.days} days, {timeLeft.hours} hours and {timeLeft.minutes} minutes.
          
        </p>
      ) : (
        <p className="subtitle">Hedera Legacy minting is live!</p>
      )}
    </div>
  );
}

export default CountDown;