import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Alert, Typography } from "@supabase/ui";
import reportWebVitals from "./reportWebVitals";

import { HashconnectProvider } from "./hooks/useHashconnectProvider";
import FooterComponentMain from "./components/Footer/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashconnectProvider>
    <Alert className="AlertTopBar">
      <Typography.Text className="AlertTopBarTxt">Alert: Check carefully and make sure you're visiting the correct url https://Hederalegacy.com/ - bookmark the URL.</Typography.Text>
</Alert>
      <App />
    </HashconnectProvider>
     <FooterComponentMain />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
