import "../../App.css";

// custom imports
import { CircularProgress, LinearProgress, ListDivider, ListItemContent, Stack } from "@mui/joy";
import Box from '@mui/joy/Box';
import { Button, Typography } from "@supabase/ui";
import WalletButton from "../../components/ConnectWallet";
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Check from "@mui/icons-material/Check";
import Slider from '@mui/joy/Slider';


import { Alert, Badge } from "@supabase/ui";
import Grid from '@mui/joy/Grid';
 


import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import "@elastic/eui/dist/eui_theme_dark.css";
import "../../toastify-dark.scss";
import {
  EuiButton,
  EuiProvider,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiPanel,
  EuiCode,
  EuiCodeBlock,
  EuiLoadingSpinner,
  EuiFlexGrid,
  EuiFieldNumber,
  EuiText,
  EuiCheckbox,
} from "@elastic/eui";
import { HashConnectTypes, MessageTypes } from "hashconnect";
import { useHashconnectProvider } from "../../hooks/useHashconnectProvider";
import {
  AccountId,
  Client,
  ContractExecuteTransaction,
  ContractCallQuery,
  ContractFunctionParameters,
  ContractFunctionResult,
  Hbar,
  HbarUnit,
  TransactionReceipt,
  TransactionRecord,
  TransactionId,
  TransactionResponse,
  ProxyStaker,
  TokenAssociateTransaction,
} from "@hashgraph/sdk";
import { toast, ToastContainer } from "react-toastify";
import ToastMessage from "../../components/ToastMessage";
import {
  MAINNET_MIRROR_NODE_BASE_URL,
  TOKENS,
  ACCOUNTS_NFTS,
  ACCOUNT_TOKENS,
  IPFS_URL,
  HL_CONTRACT_ID,
  HL_TOKEN_ID,
  HLEG_CONTRACT_ID,
  HLEG_TOKEN_ID,
  HL_CONTRACT_SLOT,
  HLEG_CONTRACT_SLOT,
  BALANCES,
  NOT_AVAILABLE,
  MAX_GAS,
  CONTRACT_STATE,
  TOKENS_NFTS,
} from "../../constants";
import { formatUnicorn } from "../../utils";
import keccak256 from "keccak256";
import Web3EthAbi from "web3-eth-abi";
import CountDown2 from "../../components/Timer2";

function MintSections() {
  const {
    accountId,
    hashconnect,
    topic,
    pairingString,
    pairingData,
    availableExtension,
    state,
    provider,
    signer,
    initHashconnect,
    connectToExtension,
    sendTransaction,
    requestAccountInfo,
    disconnect,
    clearPairings,
  } = useHashconnectProvider();

  const [isLoading, setIsLoading] = useState("");
  const [HLBalance, setHLBalance] = useState(0);
  const [HLEGBalance, setHLEGBalance] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  // const [balance, setBalance] = useState({
  //   hbar: 0,
  //   hl: 0,
  //   hleg: 0,
  // });
  const [wallet, setWallet] = useState([]);
  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [selectedToStake, setSelectedToStake] = useState([]);
  const [selectedToUnstake, setSelectedToUnstake] = useState([]);
  const [mintAmount, setMintAmount] = useState(1);
  const [isFetching, setIsFetching] = useState(true);
  const [HLInfo, setHLInfo] = useState(null);
  const [HLEGInfo, setHLEGInfo] = useState(null);
  const [HLContractState, setHLContractState] = useState(null);
  const [HLEGContractState, setHLEGContractState] = useState(null);
  const [nowInSeconds, setNowInSeconds] = useState(
    Math.round(Date.now() / 1000)
  );

  const fetchHLInfo = async () => {
    let payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HL_TOKEN_ID)
    ).json();
    setHLInfo(payload);
  };
  const fetchHLEGInfo = async () => {
    const payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HLEG_TOKEN_ID)
    ).json();
    setHLEGInfo(payload);
  };
  const fetchHLContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HL_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HL_CONTRACT_SLOT.COST:
          contractState["cost"] = Number(state.value);
          break;
        case HL_CONTRACT_SLOT.PARTNER:
          contractState["partner"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLContractState(contractState);
  };
  const fetchHLEGContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HLEG_CONTRACT_SLOT.PERFORMANCE_FEE:
          contractState["fee"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.TOTAL_STAKED:
          contractState["totalStaked"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL:
          contractState["small"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM:
          contractState["medium"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE:
          contractState["large"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL_REWARD:
          contractState["smallReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM_REWARD:
          contractState["mediumReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE_REWARD:
          contractState["largeReward"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLEGContractState(contractState);
  };
  const fetchAccountTokens = async () => {
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNT_TOKENS, {
      accountId: accountId,
      tokenId: HLEG_TOKEN_ID,
    });
    const payload = await (await fetch(url)).json();
    if (payload.tokens.length === 0) setHLEGBalance(NOT_AVAILABLE);
    else setHLEGBalance(payload.tokens[0].balance);
  };
  // const fetchAccountBalances = async () => {
  //   let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + BALANCES, {
  //     accountId: accountId,
  //   });
  //   const payload = await (await fetch(url)).json();
  //   let accountBalance = {
  //     hbar: payload.balances[0].balance,
  //     hl: NOT_AVAILABLE,
  //     hleg: NOT_AVAILABLE,
  //   };
  //   payload.balances[0].tokens.forEach((token) => {
  //     switch (token.token_id) {
  //       case HL_TOKEN_ID:
  //         accountBalance.hl = token.balance;
  //         break;
  //       case HLEG_TOKEN_ID:
  //         accountBalance.hleg = token.balance;
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   console.log('tokensize :>> ', payload.balances[0].tokens.length);
  //   // console.log("payload :>> ", payload.balances[0].tokens);
  //   setBalance(accountBalance);
  // };
  const fetchAccountNFTs = async () => {
    let nfts = [];
    let isLast = false;
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNTS_NFTS, {
      accountId: accountId,
      tokenId: HL_TOKEN_ID,
      limit: 1,
      gte: 1,
    });
    do {
      const page = await (await fetch(url)).json();
      nfts = [...nfts, ...page.nfts];
      url = MAINNET_MIRROR_NODE_BASE_URL + page.links.next;
      isLast = page.links.next === null;
    } while (!isLast);
    setHLBalance(nfts.length);
    nfts = await Promise.all(
      nfts.map(async (nft) => {
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        return {
          ...nft,
          metadata_json: metadata,
        };
      })
    );
    setWallet(nfts);
    // console.log("nfts :>> ", nfts);
  };

  const fetchStakedNFTs = async () => {
    const mySolAddr =
      "0x" + AccountId.fromString(accountId).toSolidityAddress();
    const second = keccak256(
      Web3EthAbi.encodeParameters(
        ["uint256", "uint256"],
        [mySolAddr, HLEG_CONTRACT_SLOT.STAKE_BY_OWNER.toString()]
      )
    );
    const third = keccak256(second);

    // console.log("mySolAddr :>> ", mySolAddr);
    // console.log("second :>> ", "0x" + second.toString("hex"));
    // console.log("third :>> ", "0x" + third.toString("hex"));
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + second.toString("hex"),
      limit: 1,
    });
    // console.log("url :>> ", url);
    const payload = (await (await fetch(url)).json()).state[0];
    const count = Number(
      payload.slot === "0x" + second.toString("hex") ? payload.value : 0
    );
    // console.log("count :>> ", count);
    let pageCount = Math.ceil(count / 100);
    let staked = [];

    url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + third.toString("hex"),
      limit: 100,
    });
    while (pageCount-- > 0) {
      // console.log("url :>> ", url);
      const data = await (await fetch(url)).json();
      const payload = data.state;
      for (let i = 0; i < payload.length; i++) {
        staked.push(Number(payload[i].value));
      }
      url = MAINNET_MIRROR_NODE_BASE_URL + data.links.next;
    }
    staked = staked.slice(0, count);
    setStakedAmount(staked.length);
    staked = await Promise.all(
      staked.map(async (sn) => {
        const nft = await (
          await fetch(
            formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
              tokenId: HL_TOKEN_ID,
              serialNumber: sn,
            })
          )
        ).json();
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        const BigInt = window.BigInt;
        let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
          contractId: HLEG_CONTRACT_ID,
          gte:
            "0x" +
            (
              BigInt(
                "0x" +
                  keccak256(
                    Web3EthAbi.encodeParameters(
                      ["uint256"],
                      [HLEG_CONTRACT_SLOT.STAKE_INFOS.toString()]
                    )
                  ).toString("hex")
              ) +
              BigInt(sn) * BigInt(3) +
              BigInt(1)
            ).toString(16),
          limit: 2,
        });
        // console.log("stakeinfourl :>> ", url);
        const stake = await (await fetch(url)).json();
        const period = Number(stake.state[0].value);
        const timestamp = Number(stake.state[1].value);
        const endAt = timestamp + period * 86400;
        return {
          ...nft,
          metadata_json: metadata,
          stakeInfo: {
            period,
            endAt,
          },
        };
      })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
  };

  const fetchInfos = async () => {
    if (accountId == null) return;
    console.log("fetching data at", Date.now());
    setIsFetching(true);
    await Promise.all([
      fetchHLInfo(),
      fetchHLEGInfo(),
      fetchHLContractInfo(),
      fetchHLEGContractInfo(),
      fetchAccountNFTs(),
      fetchAccountTokens(),
      fetchStakedNFTs(),
      // fetchAccountBalances(),
    ]);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchInfos();
  }, [accountId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchInfos();
      console.log('--------------------------------------------------------------------------------------')
    }, 10000);
    const smallInterval = setInterval(() => {
      (() => {
        setNowInSeconds(Math.round(Date.now() / 1000));
      })();
    }, 500);
    return () => {
      clearInterval(interval);
      clearInterval(smallInterval);
    };
  }, []);

  const onPair = () => {
    if (state === "Paired") {
      disconnect();
      clearPairings();
      window.location.reload();
    } else {
      connectToExtension();
    }
  };
  const callQuery = async (contractId, fn, params) => {
    // const account = pairingData?.accountIds[0];
    const query = new ContractCallQuery()
      .setGas(MAX_GAS)
      .setContractId(HL_CONTRACT_ID)
      .setFunction(fn, params)
      .setQueryPayment(Hbar.fromTinybars(300000));
    // const queryCost = await query.getCost(client);
    let transactionBytes = query.toBytes();

    let res = await sendTransaction(
      transactionBytes,
      accountId,
      false,
      false,
      false
    );
    // console.log("res :>> ", res);
    return res;
  };

  const executeContract = async (contractId, fn, params, payableAmount = 0) => {
    let fnResult = null;
    try {
      const trans = new ContractExecuteTransaction()
        .setContractId(contractId)
        .setGas(MAX_GAS) // Increase if revert
        .setPayableAmount(payableAmount) // Increase if revert
        .setFunction(fn, params);
      trans.freezeWithSigner(signer);
      let transactionBytes = trans.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        true
      );
      if (res.success) {
        let arr = new Uint8Array(Object.keys(res.record).length);
        for (let i = 0; i < Object.keys(res.record).length; i++) {
          arr[i] = res.record[i];
        }
        fnResult = new ContractFunctionResult({ bytes: arr });
        // console.log(
        //   `ContractExecuteTransaction ${fn}(${params}) :>> ${fnResult}`
        // );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You rejected the transaction, try again."
          />,
          {
            toastId: fn,
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: fn,
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: fn,
        }
      );
    }
    return fnResult;
  };

  // const getBalance = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("balance");
  //   const txResult = await executeContract(
  //     "balanceOf",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (txResult) {
  //     setNFTBalance(txResult.getUint256(0));
  //   }
  //   setIsLoading("");
  // };

  // const fetchWallet = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("wallet");
  //   const walletResult = await executeContract(
  //     "walletOfOwner",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (walletResult) {
  //     const bal = walletResult.getUint256(1);
  //     setNFTBalance(bal);
  //   }
  //   setIsLoading("");
  // };

  const associate = async () => {
    try {
      const tx = new TokenAssociateTransaction()
        .setAccountId(accountId)
        // .setGas(MAX_GAS) // Increase if revert
        .setTokenIds([HLEG_TOKEN_ID]);
      tx.freezeWithSigner(signer);
      const signedTx = await signer.signTransaction(tx);
      let transactionBytes = signedTx.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        false
      );
      // console.log("res :>> ", res);
      if (res.success) {
        toast.success(
          <ToastMessage
            color="success"
            headerText="Success!"
            bodyText="You are now OK to start staking."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You should approve to associate $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error.status === "TOKEN_ALREADY_ASSOCIATED_TO_ACCOUNT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You have already associated $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: "associate",
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: "associate",
        }
      );
    }
    fetchInfos();
  };

  const onAssociate = async () => {
    setIsLoading("associate");
    await associate();
    setIsLoading("");
  };

  const onMint = async () => {
    setIsLoading("mint");
    const txResult = await executeContract(
      HL_CONTRACT_ID,
      "mint",
      new ContractFunctionParameters().addUint256(mintAmount),
      Hbar.from(0 * mintAmount)
    );
    if (txResult) {
      toast.success(
        <ToastMessage
          color="success"
          headerText="Success!"
          bodyText="Successfully minted."
        />,
        {
          toastId: "mint",
        }
      );
      // fetchWallet();
      fetchInfos();
    }
    setIsLoading("");
  };

  const onStake = async (period) => {
    if (HLEGBalance === NOT_AVAILABLE) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="You have to associate $HLEG to claim rewards after staking. First do it and try again."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    if (selectedToStake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    setIsLoading("stake" + period);
    const txResult = await executeContract(
      HLEG_CONTRACT_ID,
      "stake",
      new ContractFunctionParameters()
        .addInt64Array(selectedToStake)
        .addUint256(period),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText="Successfully staked."
      />,
      {
        toastId: "stake",
      }
    );
    // const newNFTs = wallet.filter(
    //   (nft) => selectedToStake.findIndex((sn) => sn === nft.serial_number) >= 0
    // );
    // setWallet(newNFTs);
    setSelectedToStake([]);
    fetchInfos();
    setIsLoading("");
  };

  const onUnstake = async () => {
    if (selectedToUnstake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "unstake",
        }
      );
      return;
    }
    setIsLoading("unstake");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "unstake",
      new ContractFunctionParameters().addInt64Array(selectedToUnstake),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    const claimed = result.getUint256(0) / Math.pow(10, 10);

    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText={`You have claimed ${claimed} $HLEG`}
      />,
      {
        toastId: "unstake",
      }
    );
    // const newNFTs = stakedNFTs.filter(
    //   (nft) =>
    //     selectedToUnstake.findIndex((sn) => sn === nft.serial_number) === -1
    // );
    // setWallet(newNFTs);
    setSelectedToUnstake([]);
    fetchInfos();
    setIsLoading("");
  };

  const onView = async () => {
    setIsLoading("view");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "myStake",
      new ContractFunctionParameters()
    );
    setIsLoading("");

    // const typeSize = result.getUint256(0);
    const count = result.getUint256(1);
    // console.log(`${typeSize}  ${count}`);
    let staked = [];
    for (let i = 0; i < count; i++) {
      const serialNumber = result.getUint160(i * 3 + 2);
      const period = result.getUint256(i * 3 + 3);
      const timestamp = result.getUint256(i * 3 + 4);
      // console.log(
      //   `{\n\t${serialNumber},\n\t${period},\n\t${new Date(
      //     timestamp * 1000
      //   ).toLocaleString()},\n}`
      // );
      staked.push({
        serialNumber,
        period,
        timestamp,
        endAt: Number(timestamp) + Number(period) * 86400,
      });
    }
    staked = await Promise.all(
      staked
        .sort((a, b) => a.serialNumber - b.serialNumber)
        .map(async (stake) => {
          const nft = await (
            await fetch(
              formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
                tokenId: HL_TOKEN_ID,
                serialNumber: stake.serialNumber,
              })
            )
          ).json();
          const metadata = await (
            await fetch(
              IPFS_URL + Buffer.from(nft.metadata, "base64").toString()
            )
          ).json();
          return {
            ...nft,
            metadata_json: metadata,
            stakeInfo: stake,
          };
        })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
    setIsLoading("");
  };

  const onAmountChange = (e) => {
    if (e.target.value > 0 && e.target.value <= 10)
      setMintAmount(e.target.value);
  };

  const onStakeCheck = (serialNumber) => {
    let origin = selectedToStake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToStake(origin);
  };

  const onUnstakeCheck = (serialNumber) => {
    let origin = selectedToUnstake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToUnstake(origin);
  };

  useEffect(() => {
    // fetchWallet();
  }, []);


  const Item = styled(Sheet)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.vars.palette.text.tertiary,
  }));


  const videoRef = useRef(undefined);
  useEffect(() => {
      videoRef.current.defaultMuted = true;
  })



  const marks = [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },    
    {
      value: 5,
    },    
    {
      value: 6,
    },    
    {
      value: 7,
    },    
    {
      value: 8,
    },    
    {
      value: 9,
    },    
    {
      value: 10,
    },
  ];


  function valueText(value) {
    return `${value}`;
  }

  return (
    <>
      {isFetching && accountId ? (
 ''
      ) : (
        ""
      )}


          <ToastContainer />

 
          <h2 className="title text--title">Mint 
</h2>     

<p className="subtitle">
Welcome, {state !== "Connected" && state !== "Paired" ? (
<EuiLoadingSpinner className={"spinner"} />
 ) : state === "Paired" ? (pairingData?.accountIds[0] ) 
 :
  ("Pair your Hashpack Wallet to get started")}

  
</p>

















<div className="v-row">



<div className="v-col-sm-12 v-col-md-12 v-col-lg-6 v-col-12">
<div className="base-banner rounded-2xl d-flex flex-column flex-sm-row-reverse justify-center justify-sm-space-between stakeBanner" style={{ height: '100%' }}>
<div className="base-banner__background">
 
</div>



<div className="base-banner__content">
<div className="v-row">

 

<video
            className="VideoTag bbb-ins"
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline>
            <source src={require('../../images/Outfit.mp4')} type="video/mp4"/>
        </video>


 
</div>
</div>


</div>
</div>




<div className="v-col-sm-12 v-col-md-12 v-col-lg-6 v-col-12">
<div className="base-banner rounded-2xl d-flex flex-column flex-sm-row-reverse justify-center justify-sm-space-between stakeBanner sentient--1">
<div className="base-banner__background">
<div className="v-responsive v-img">
<div className="v-responsive__sizer" style={{ paddingBottom: "23.0916%" }}>
<div className="v-img__img v-img__img--cover glassMorph" style={{ opacity: '.8', border: '1px solid', borderColor: '#73738c3d' }} />

</div>
</div>
</div>



<div className="base-banner__content2">
<div className="v-row">
 
<div className="v-col-sm-3 v-col-12 align-self-center" style={{ Width: '100%' }}>
<h2 className="title text--title">ESSENTIALS <Badge dot>Free mint</Badge> <Badge dot>Public mint</Badge> </h2>


  <Box>
     {/* <Slider
        min={1}
        max={10}
        aria-label="Custom marks"
        defaultValue={1}
        getAriaValueText={valueText}
        step={1}
        value={mintAmount}
        onChange={(e) => onAmountChange(e)}
        valueLabelDisplay="auto"
        marks={marks}
      />*/}

<Button disabled disabled={state !== "Paired" || isLoading.length > 0} className="primary">
{isLoading === "mint" ? (
                            <EuiLoadingSpinner className={"spinner"} />
                          ) : (
                       ""
                       )}
SOLD OUT
  </Button>
   {HLContractState
                              ? " " +
                                Hbar.fromTinybars(
                                  HLContractState?.cost
                                ).toString() +
                                " Per HL "
                              : ""} 
                                </Box>

 
</div>
 
</div>
</div>

</div>
<p style={{ padding: '20px' }}>From the wardrobe of Hedera Legacy, streetwear visions anew the way of luxury clothing for the Hedera community.
<br></br><br></br> Essentials is a collection of 111 animated NFTs on the Hedera network, this collection is <strong>free mint</strong>.</p>
</div>

</div>














 


                  
                  
                  
 

    </>
  );
}

export default MintSections;
