import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { Button } from '@supabase/ui'
import IconButton from '@mui/joy/IconButton';


// Icons import
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

// custom
import filesTheme from './theme.tsx';
import Layout from '.././../components/Layout.tsx';
import Navigation from '.././../components/Navigation.tsx';
import Staking from './PageInnder';
import TopHeadComp from '../../components/Header/TopHead';


export default function FilesExample() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <CssVarsProvider  disableTransitionOnChange theme={filesTheme}>
          <div className="ball-background MuiBox-root css-0">
            
            <div className="v-responsive v-img ball ball--blue MuiBox-root css-0">
              <div className="v-responsive__sizer MuiBox-root css-0">
              <img src={require('../../images/greenball.png')} className="image-123"/>
      
      </div>
                </div><div className="v-responsive v-img ball ball--green MuiBox-root css-0">
                  <div className="v-responsive__sizer MuiBox-root css-0">
                  <img src={require('../../images/greenball.png')} className="image-123"/>
      
      </div>              </div>
                    <div className="v-responsive v-img ball ball--purple MuiBox-root css-0">
                      <div className="v-responsive__sizer MuiBox-root css-0">
                      <img src={require('../../images/greenball.png')} className="image-123"/>
      </div>
                        </div>
                        <div className="ball ball--2 io-bg-2"></div>
                        <div className="ball ball--3 io-bg-3"></div>
       
      
                        </div>
      <CssBaseline />
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Navigation />
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
            md: 'minmax(160px, 300px) minmax(600px, 1fr)',
          },
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
<TopHeadComp />
        <Layout.SideNav>
          <Navigation />
        </Layout.SideNav>
        <Layout.Main>
          <Staking />
        </Layout.Main>
 
      </Layout.Root>
    </CssVarsProvider>
  );
}
