import "../../App.css";
import Sheet from '@mui/joy/Sheet';
import { CircularProgress, LinearProgress, ListItemContent, Stack, Typography } from "@mui/joy";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Box from '@mui/joy/Box';
import Cloud from '@mui/icons-material/Cloud';
import Check from "@mui/icons-material/Check";

import AvatarGroup from '@mui/joy/AvatarGroup';
import Avatar from '@mui/joy/Avatar';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import AspectRatio from '@mui/joy/AspectRatio';
import IconButton from '@mui/joy/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Grid from '@mui/joy/Grid';
import { styled } from '@mui/joy/styles';
import WalletButton from "../../components/ConnectWallet";


// CUSTOM ALERT BOX
import { Alert, Badge } from "@supabase/ui";

// Custom Checkbox
import { Checkbox } from "@supabase/ui";


// Custom tabs
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { tabClasses } from '@mui/joy/Tab';

// Custom Badge

// custom Card 
import Link from '@mui/joy/Link';
import Chip from '@mui/joy/Chip';

// custom bottom nav
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';



// custom tabs staking
import { Button } from "@supabase/ui";



import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import "@elastic/eui/dist/eui_theme_dark.css";
import "../../toastify-dark.scss";
import {
  EuiButton,
  EuiProvider,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiPanel,
  EuiCode,
  EuiCodeBlock,
  EuiLoadingSpinner,
  EuiFlexGrid,
  EuiFieldNumber,
  EuiText,
} from "@elastic/eui";
import { HashConnectTypes, MessageTypes } from "hashconnect";
import { useHashconnectProvider } from "../../hooks/useHashconnectProvider";
import {
  AccountId,
  Client,
  ContractExecuteTransaction,
  ContractCallQuery,
  ContractFunctionParameters,
  ContractFunctionResult,
  Hbar,
  HbarUnit,
  TransactionReceipt,
  TransactionRecord,
  TransactionId,
  TransactionResponse,
  ProxyStaker,
  TokenAssociateTransaction,
} from "@hashgraph/sdk";
import { toast, ToastContainer } from "react-toastify";
import ToastMessage from "../../components/ToastMessage";
import {
  MAINNET_MIRROR_NODE_BASE_URL,
  TOKENS,
  ACCOUNTS_NFTS,
  ACCOUNT_TOKENS,
  IPFS_URL,
  HL_CONTRACT_ID,
  HL_TOKEN_ID,
  HLEG_CONTRACT_ID,
  HLEG_TOKEN_ID,
  HL_CONTRACT_SLOT,
  HLEG_CONTRACT_SLOT,
  BALANCES,
  NOT_AVAILABLE,
  MAX_GAS,
  CONTRACT_STATE,
  TOKENS_NFTS,
} from "../../constants";
import { formatUnicorn } from "../../utils";
import keccak256 from "keccak256";
import Web3EthAbi from "web3-eth-abi";
import ImageBannerGreen from "../../components/ImgBanner";
import BadgeFinish from "../../components/BadgeFinish";

function Dashboard() {
  const {
    accountId,
    hashconnect,
    topic,
    pairingString,
    pairingData,
    availableExtension,
    state,
    provider,
    signer,
    initHashconnect,
    connectToExtension,
    sendTransaction,
    requestAccountInfo,
    disconnect,
    clearPairings,
  } = useHashconnectProvider();

  const [isLoading, setIsLoading] = useState("");
  const [HLBalance, setHLBalance] = useState(0);
  const [HLEGBalance, setHLEGBalance] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  // const [balance, setBalance] = useState({
  //   hbar: 0,
  //   hl: 0,
  //   hleg: 0,
  // });
  const [wallet, setWallet] = useState([]);
  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [selectedToStake, setSelectedToStake] = useState([]);
  const [selectedToUnstake, setSelectedToUnstake] = useState([]);
  const [mintAmount, setMintAmount] = useState(1);
  const [isFetching, setIsFetching] = useState(true);
  const [HLInfo, setHLInfo] = useState(null);
  const [HLEGInfo, setHLEGInfo] = useState(null);
  const [HLContractState, setHLContractState] = useState(null);
  const [HLEGContractState, setHLEGContractState] = useState(null);
  const [nowInSeconds, setNowInSeconds] = useState(
    Math.round(Date.now() / 1000)
  );

  const Item = styled(Sheet)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.vars.palette.text.tertiary,
  }));

  const fetchHLInfo = async () => {
    let payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HL_TOKEN_ID)
    ).json();
    setHLInfo(payload);
  };
  const fetchHLEGInfo = async () => {
    const payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HLEG_TOKEN_ID)
    ).json();
    setHLEGInfo(payload);
  };
  const fetchHLContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HL_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HL_CONTRACT_SLOT.COST:
          contractState["cost"] = Number(state.value);
          break;
        case HL_CONTRACT_SLOT.PARTNER:
          contractState["partner"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLContractState(contractState);
  };
  const fetchHLEGContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HLEG_CONTRACT_SLOT.PERFORMANCE_FEE:
          contractState["fee"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.TOTAL_STAKED:
          contractState["totalStaked"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL:
          contractState["small"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM:
          contractState["medium"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE:
          contractState["large"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL_REWARD:
          contractState["smallReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM_REWARD:
          contractState["mediumReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE_REWARD:
          contractState["largeReward"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLEGContractState(contractState);
  };
  const fetchAccountTokens = async () => {
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNT_TOKENS, {
      accountId: accountId,
      tokenId: HLEG_TOKEN_ID,
    });
    const payload = await (await fetch(url)).json();
    if (payload.tokens.length === 0) setHLEGBalance(NOT_AVAILABLE);
    else setHLEGBalance(payload.tokens[0].balance);
  };
  // const fetchAccountBalances = async () => {
  //   let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + BALANCES, {
  //     accountId: accountId,
  //   });
  //   const payload = await (await fetch(url)).json();
  //   let accountBalance = {
  //     hbar: payload.balances[0].balance,
  //     hl: NOT_AVAILABLE,
  //     hleg: NOT_AVAILABLE,
  //   };
  //   payload.balances[0].tokens.forEach((token) => {
  //     switch (token.token_id) {
  //       case HL_TOKEN_ID:
  //         accountBalance.hl = token.balance;
  //         break;
  //       case HLEG_TOKEN_ID:
  //         accountBalance.hleg = token.balance;
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   console.log('tokensize :>> ', payload.balances[0].tokens.length);
  //   // console.log("payload :>> ", payload.balances[0].tokens);
  //   setBalance(accountBalance);
  // };
  const fetchAccountNFTs = async () => {
    let nfts = [];
    let isLast = false;
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNTS_NFTS, {
      accountId: accountId,
      tokenId: HL_TOKEN_ID,
      limit: 1,
      gte: 1,
    });
    do {
      const page = await (await fetch(url)).json();
      nfts = [...nfts, ...page.nfts];
      url = MAINNET_MIRROR_NODE_BASE_URL + page.links.next;
      isLast = page.links.next === null;
    } while (!isLast);
    setHLBalance(nfts.length);
    nfts = await Promise.all(
      nfts.map(async (nft) => {
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        return {
          ...nft,
          metadata_json: metadata,
        };
      })
    );
    setWallet(nfts);
    // console.log("nfts :>> ", nfts);
  };

  const fetchStakedNFTs = async () => {
    const mySolAddr =
      "0x" + AccountId.fromString(accountId).toSolidityAddress();
    const second = keccak256(
      Web3EthAbi.encodeParameters(
        ["uint256", "uint256"],
        [mySolAddr, HLEG_CONTRACT_SLOT.STAKE_BY_OWNER.toString()]
      )
    );
    const third = keccak256(second);

    // console.log("mySolAddr :>> ", mySolAddr);
    // console.log("second :>> ", "0x" + second.toString("hex"));
    // console.log("third :>> ", "0x" + third.toString("hex"));
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + second.toString("hex"),
      limit: 1,
    });
    // console.log("url :>> ", url);
    const payload = (await (await fetch(url)).json()).state[0];
    const count = Number(
      payload.slot === "0x" + second.toString("hex") ? payload.value : 0
    );
    // console.log("count :>> ", count);
    let pageCount = Math.ceil(count / 100);
    let staked = [];

    url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + third.toString("hex"),
      limit: 100,
    });
    while (pageCount-- > 0) {
      // console.log("url :>> ", url);
      const data = await (await fetch(url)).json();
      const payload = data.state;
      for (let i = 0; i < payload.length; i++) {
        staked.push(Number(payload[i].value));
      }
      url = MAINNET_MIRROR_NODE_BASE_URL + data.links.next;
    }
    staked = staked.slice(0, count);
    setStakedAmount(staked.length);
    staked = await Promise.all(
      staked.map(async (sn) => {
        const nft = await (
          await fetch(
            formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
              tokenId: HL_TOKEN_ID,
              serialNumber: sn,
            })
          )
        ).json();
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        const BigInt = window.BigInt;
        let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
          contractId: HLEG_CONTRACT_ID,
          gte:
            "0x" +
            (
              BigInt(
                "0x" +
                  keccak256(
                    Web3EthAbi.encodeParameters(
                      ["uint256"],
                      [HLEG_CONTRACT_SLOT.STAKE_INFOS.toString()]
                    )
                  ).toString("hex")
              ) +
              BigInt(sn) * BigInt(3) +
              BigInt(1)
            ).toString(16),
          limit: 2,
        });
        // console.log("stakeinfourl :>> ", url);
        const stake = await (await fetch(url)).json();
        const period = Number(stake.state[0].value);
        const timestamp = Number(stake.state[1].value);
        const endAt = timestamp + period * 86400;
        return {
          ...nft,
          metadata_json: metadata,
          stakeInfo: {
            period,
            endAt,
          },
        };
      })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
  };

  const fetchInfos = async () => {
    if (accountId == null) return;
    console.log("fetching data at", Date.now());
    setIsFetching(true);
    await Promise.all([
      fetchHLInfo(),
      fetchHLEGInfo(),
      fetchHLContractInfo(),
      fetchHLEGContractInfo(),
      fetchAccountNFTs(),
      fetchAccountTokens(),
      fetchStakedNFTs(),
      // fetchAccountBalances(),
    ]);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchInfos();
  }, [accountId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchInfos();
      console.log('--------------------------------------------------------------------------------------')
    }, 10000);
    const smallInterval = setInterval(() => {
      (() => {
        setNowInSeconds(Math.round(Date.now() / 1000));
      })();
    }, 500);
    return () => {
      clearInterval(interval);
      clearInterval(smallInterval);
    };
  }, []);

  const onPair = () => {
    if (state === "Paired") {
      disconnect();
      clearPairings();
      window.location.reload();
    } else {
      connectToExtension();
    }
  };
  const callQuery = async (contractId, fn, params) => {
    // const account = pairingData?.accountIds[0];
    const query = new ContractCallQuery()
      .setGas(MAX_GAS)
      .setContractId(HL_CONTRACT_ID)
      .setFunction(fn, params)
      .setQueryPayment(Hbar.fromTinybars(300000));
    // const queryCost = await query.getCost(client);
    let transactionBytes = query.toBytes();

    let res = await sendTransaction(
      transactionBytes,
      accountId,
      false,
      false,
      false
    );
    // console.log("res :>> ", res);
    return res;
  };

  function handleOnChange(e) {
    // use e.target.checked
  }

  const executeContract = async (contractId, fn, params, payableAmount = 0) => {
    let fnResult = null;
    try {
      const trans = new ContractExecuteTransaction()
        .setContractId(contractId)
        .setGas(MAX_GAS) // Increase if revert
        .setPayableAmount(payableAmount) // Increase if revert
        .setFunction(fn, params);
      trans.freezeWithSigner(signer);
      let transactionBytes = trans.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        true
      );
      if (res.success) {
        let arr = new Uint8Array(Object.keys(res.record).length);
        for (let i = 0; i < Object.keys(res.record).length; i++) {
          arr[i] = res.record[i];
        }
        fnResult = new ContractFunctionResult({ bytes: arr });
        // console.log(
        //   `ContractExecuteTransaction ${fn}(${params}) :>> ${fnResult}`
        // );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You should approve to execute the transaction."
          />,
          {
            toastId: fn,
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: fn,
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: fn,
        }
      );
    }
    return fnResult;
  };

  // const getBalance = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("balance");
  //   const txResult = await executeContract(
  //     "balanceOf",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (txResult) {
  //     setNFTBalance(txResult.getUint256(0));
  //   }
  //   setIsLoading("");
  // };

  // const fetchWallet = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("wallet");
  //   const walletResult = await executeContract(
  //     "walletOfOwner",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (walletResult) {
  //     const bal = walletResult.getUint256(1);
  //     setNFTBalance(bal);
  //   }
  //   setIsLoading("");
  // };

  const associate = async () => {
    try {
      const tx = new TokenAssociateTransaction()
        .setAccountId(accountId)
        // .setGas(MAX_GAS) // Increase if revert
        .setTokenIds([HLEG_TOKEN_ID]);
      tx.freezeWithSigner(signer);
      const signedTx = await signer.signTransaction(tx);
      let transactionBytes = signedTx.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        false
      );
      // console.log("res :>> ", res);
      if (res.success) {
        toast.success(
          <ToastMessage
            color="success"
            headerText="Success!"
            bodyText="You are now OK to start staking."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You should approve to associate $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error.status === "TOKEN_ALREADY_ASSOCIATED_TO_ACCOUNT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You have already associated $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: "associate",
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: "associate",
        }
      );
    }
    fetchInfos();
  };

  const onAssociate = async () => {
    setIsLoading("associate");
    await associate();
    setIsLoading("");
  };

  const onMint = async () => {
    setIsLoading("mint");
    const txResult = await executeContract(
      HL_CONTRACT_ID,
      "mint",
      new ContractFunctionParameters().addUint256(mintAmount),
      Hbar.from(10 * mintAmount)
    );
    if (txResult) {
      toast.success(
        <ToastMessage
          color="success"
          headerText="Success!"
          bodyText="Successfully minted."
        />,
        {
          toastId: "mint",
        }
      );
      // fetchWallet();
      fetchInfos();
    }
    setIsLoading("");
  };

  const onStake = async (period) => {
    if (HLEGBalance === NOT_AVAILABLE) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="You have to associate $HLEG to claim rewards after staking. First do it and try again."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    if (selectedToStake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    setIsLoading("stake" + period);
    const txResult = await executeContract(
      HLEG_CONTRACT_ID,
      "stake",
      new ContractFunctionParameters()
        .addInt64Array(selectedToStake)
        .addUint256(period),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText="Successfully staked."
      />,
      {
        toastId: "stake",
      }
    );
    // const newNFTs = wallet.filter(
    //   (nft) => selectedToStake.findIndex((sn) => sn === nft.serial_number) >= 0
    // );
    // setWallet(newNFTs);
    setSelectedToStake([]);
    fetchInfos();
    setIsLoading("");
  };

  const onUnstake = async () => {
    if (selectedToUnstake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "unstake",
        }
      );
      return;
    }
    setIsLoading("unstake");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "unstake",
      new ContractFunctionParameters().addInt64Array(selectedToUnstake),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    const claimed = result.getUint256(0) / Math.pow(10, 10);

    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText={`You have claimed ${claimed} $HLEG`}
      />,
      {
        toastId: "unstake",
      }
    );
    // const newNFTs = stakedNFTs.filter(
    //   (nft) =>
    //     selectedToUnstake.findIndex((sn) => sn === nft.serial_number) === -1
    // );
    // setWallet(newNFTs);
    setSelectedToUnstake([]);
    fetchInfos();
    setIsLoading("");
  };


  
  const onView = async () => {
    setIsLoading("view");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "myStake",
      new ContractFunctionParameters()
    );
    setIsLoading("");

    // const typeSize = result.getUint256(0);
    const count = result.getUint256(1);
    // console.log(`${typeSize}  ${count}`);
    let staked = [];
    for (let i = 0; i < count; i++) {
      const serialNumber = result.getUint160(i * 3 + 2);
      const period = result.getUint256(i * 3 + 3);
      const timestamp = result.getUint256(i * 3 + 4);
      // console.log(
      //   `{\n\t${serialNumber},\n\t${period},\n\t${new Date(
      //     timestamp * 1000
      //   ).toLocaleString()},\n}`
      // );
      staked.push({
        serialNumber,
        period,
        timestamp,
        endAt: Number(timestamp) + Number(period) * 86400,
      });
    }
    staked = await Promise.all(
      staked
        .sort((a, b) => a.serialNumber - b.serialNumber)
        .map(async (stake) => {
          const nft = await (
            await fetch(
              formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
                tokenId: HL_TOKEN_ID,
                serialNumber: stake.serialNumber,
              })
            )
          ).json();
          const metadata = await (
            await fetch(
              IPFS_URL + Buffer.from(nft.metadata, "base64").toString()
            )
          ).json();
          return {
            ...nft,
            metadata_json: metadata,
            stakeInfo: stake,
          };
        })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
    setIsLoading("");
  };

  const onAmountChange = (e) => {
    if (e.target.value > 0 && e.target.value <= 10)
      setMintAmount(e.target.value);
  };

  const onStakeCheck = (serialNumber) => {
    let origin = selectedToStake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToStake(origin);
  };

  const onUnstakeCheck = (serialNumber) => {
    let origin = selectedToUnstake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToUnstake(origin);
  };

  useEffect(() => {
    // fetchWallet();
  }, []);

 
  return (
    <>

<Box
              variant="outlined"
              sx={{
                borderRadius: 'sm',
                '& > *': {
                  p: 0,
                  '&:nth-child(n):not(:nth-last-child(-n+4))': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  },
                },
              }}
            >
              
      <Grid container spacing={2} sx={{ flexGrow: 0, bgcolor: 'transparent' }}>




 
      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
Overview
</Typography>
<Box>
<Typography level="body2">
The market for Non-Fungible Tokens (NFTs) is rapidly evolving and expanding. On all blockchains and ecosystems, NFT projects and opportunities are flourishing and accelerating.
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
However, each blockchain suffers with specific and particular problems: high fees, scalability, security, or lack of users. To solve this problem, Hedera Legacy partnered up with Bunny Punk NFT on the Elastos Smart Chain, and has developed a NFT cross-chain bridge to allow interoperability with other blockchains, and collaboration in product design.
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Bunny Punk NFT has been building in the NFT space and Web3 development for several years. They have a history of collaborating and working with other projects, handling challenges and engaging in opportunities.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
With the myriad options proliferating in digital currencies, NFTs and DeFi, we believe that the future of Web3 will be all about cross-chain interoperability. The team is devoted to contribute to interoperability solutions between chains, making multichain collaborations.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
You can also read more about the link between Hedera Legacy & Bunny Bunk in our latest <a className="finish" href="https://medium.com/@HederaLegacy/bp-to-hl-on-hedera-57c184e97252">Medium article</a> , a quick read packed with all the details that shows how this idea was born.
</Typography>
</Box>

 
          </Item>
      </Grid>
 

 
      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
Vision
</Typography>
<Box>
<Typography level="body2">
Hedera Legacy: The Dream “Our goal is to attract as many NFT enthusiasts across multiple chains . We hope that, with the popularity of NFTs, the Hedera community will grow and grow, allowing all users to be more aware of what can be achieved on all the associated chains."

</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
A key product that allows users to access and interoperate with other chains and platforms is the cross-chain bridge. With the future of Web3 moving towards interoperability, cross-chain bridges are needed for users to interact with one another and collaborate while building innovative products, for cryptocurrencies and NFTs alike.

</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Each growing blockchain has its own unique strengths, such as: security, transactions, scalability, speed and user activity. Our mission is to bring NFTs from other chains to the Hedera Network and, in turn, from Hedera to wider markets through our NFT cross-chain bridge. This means greater exposure for NFT creators, and better liquidity for asset holders.

</Typography>
</Box>
          </Item>
      </Grid>
 
      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
$HLEG Token
</Typography>
<Box>
<Typography level="body2">
$HLEG will be the utility token built on Hedera Network that will power the cross-chain bridge. The $HLEG Token will have a main function: access to our bridge. After the launch, $HLEG will be available on Saucerswap, and on Chainge Finance that will facilitate cross-chain swaps
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Tokenomics</Badge>
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
$HLEG distribution and tokenomics Schedule
</Typography>
</Box>
 
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● $HLEG is pre-mined with a total supply of 50,000,000 tokens
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● $HLEG token will be utilized as payment for the bridge and will have a staking utility
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● Each TX made on the cross-chain bridge, staking dapp and farming dapp will burn X amount tokens instantly, to be determined after the token launch
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● $HLEG is deflationary
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● As the value of the token varies, the bridge fee will adjust accordingly
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● Reports of the burned $HLEG tokens will be provided quarterly
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Token Launch</Badge>
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
The $HLEG token launch is coming and will be available on SaucerSwap. $HLEG is the utility token on the Hedera Network that will power the cross-chain NFT bridge. $HLEG Token will have the main function of granting access to the bridge.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
$HLEG is the utility token on the Hedera Network that will power the cross-chain NFT bridge. $HLEG Token will have the main function of granting access to the bridge.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
● Token name: $HLEG (Hedera Legacy)
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● Total token supply: 50,000,000
</Typography>
</Box>



<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Liquidity</Badge>
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
There will be a liquidity pool on SaucerSwap. In the future this will be extended to new chains as the bridge grows to interoperate with other ecosystems.
</Typography>
</Box>


          </Item>
      </Grid>






      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
NFT staking
</Typography>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Pool 1A (Ecosystem)
</Badge>
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Manual HL NFT for $HLEG - Stake your HL NFT and earn $HLEG, claim and compound manually. Will be active soon after the launch
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Pool 2A (Ecosystem)</Badge>
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Stake Hedera Legacy NFT for future benefits; like Whitelist spots and airdrops
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
● Each NFT has a minting price of 1,475 $HBAR
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● Each Hedera Legacy staked earns 1 credit point a day. 90 credit points will give access to claim your Whitelist Spot for upcoming drops or will make you eligible for airdrops.
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
<Badge size="small" color="finish" dot>Special spool (Community)</Badge>
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
This pool will have its individual start/end date within a certain block time. The special pool Hedera Legacy for $HBAR will get funded by the treasury.
</Typography>
</Box>


<Box>
<Typography level="body2" sx={{ marginTop: '.3rem' }}>
● Stake HL NFT for $HBAR
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
In celebration of our launch, the community pool will be the first to open until one month after the launch. Everybody will be eligible to stake their NFT to earn $HBAR
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
After the celebration, the pool will have a block-time started/block-time ends mechanism. This means first-come first-serve (FCFS) principle.
</Typography>
</Box>





          </Item>
      </Grid>

      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
NFT cross-chain bridge
</Typography>
<Box>
<Typography level="body2">
The evolution of NFT’s is just getting started and rapidly expanding. To be most effective as an ecosystem and to prevent lagging behind in NFT innovation, there has to be a cross-chain interoperability in NFTs. The space is maturing and we believe that over time more will realize the importance of NFTs. They can be used and programmed in an infinite number of ways. They can represent ownership, QR-codes, characters in the gaming industry, digital version of vehicles service booklet, and many more. The implementation of the cross-chain bridge is needed and necessary.
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
A key product that allows users to access and interoperate with other chains and platforms is the cross-chain bridge. With the future of Web3 moving towards interoperability, cross-chain bridges are needed for users to interact with one another and collaborate while building innovative products, for cryptocurrencies and NFTs alike.

</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Our cross-chain bridge will use a mint-and-burn protocol to preserve the integrity, scarcity and ownership of the original asset. When the NFT leaves one blockchain, it is locked, and an equivalent NFT is minted on the target blockchain. Conversely, when the NFT bridges back to its original network, the “wrapped” NFT is burned. All associated NFT assets will be able to bridge to other chains and back again.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
The bridge can be accessed through a Web3 via Walletconnect and/or Hashpack Wallet through the website. Both individual NFT assets or collections can be moved cross-chain and this service will be facilitated by the $HLEG token. Each transfer made on the cross-chain bridge will burn an amount of $HLEG tokens instantly. As the value of the token varies, the bridge fee will adjust accordingly. Reports of the burned $HLEG tokens will be provided quarterly
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
The lack of interoperability is a problem for the future of NFT innovation. Therefore, Hedera Legacy & Bunny Punk persist in building and expanding the multichain vision.
</Typography>
</Box>
<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
Over the last couple of months we had many challenges in building a NFT staking platform and a cross-chain bridge for NFTs. Among the many challenges we had is integrating the Hedera Network to our already excising platform and bridge (Bunny Hop).
</Typography>
</Box>





          </Item>
      </Grid>




      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
Treasury
</Typography>
<Box>
<Typography level="body2">
The treasury will significantly benefit the project. It will function as liquidity allocation for the special pools as mentioned in the category staking pools. There will also be a portion of the funds added to the treasury with each NFT minted.
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
We believe by managing the treasury wisely and responsibly, the project can benefit in the long run. We, as community members, want to support a sustainable model where we can reward those engaging with our project.
</Typography>
</Box>

          </Item>
      </Grid>


      
      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
Marketing & PR
</Typography>
<Box>
<Typography level="body2">
The surge of interest in crypto technologies and NFTs, and the myriad options available on a multitude of networks makes it hard to reach a specific audience, particularly for nascent upcoming young projects. Therefore, marketing tools are necessary for the growth of our project and future collaborations.
</Typography>
</Box>


          </Item>
      </Grid>
      <Grid xs={12} md={12}>
        <Item sx={{ bgcolor: 'transparent', textAlign: 'left', borderRadius: 'md', border: '1px solid', borderColor: 'divider' }}>
          <Typography
          level="h6"
            className="finish"
            strong>
Operation & Development
</Typography>
<Box>
<Typography level="body2">
A blockchain bridge provides a connection between other chains. This involves continually updating and maintaining the cross-chain bridge. A significant challenge is to maintain the highest level of security. This means developers will monitor and evaluate continuously.
</Typography>
</Box>

<Box>
<Typography level="body2" sx={{ marginTop: '1rem' }}>
The cross-chain bridge will initiate in BETA phase. This means that all major features and functions are working, but its efficiency and security still needs further testing. This can only be accomplished with the use of the bridge. Any bugs discovered by users and developers will be swiftly resolved by our development team.
</Typography>
</Box>

          </Item>
      </Grid>

    </Grid>


 </Box>

    </>
  );
}

export default Dashboard;
