import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import IconButton from '@mui/joy/IconButton';
import { Button } from '@supabase/ui'
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import Box from '@mui/joy/Box';
import Navigation from '../Navigation.tsx';
import Typography from '@mui/joy/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Layout from '.././../components/Layout.tsx';
import WalletButton from '../../components/ConnectWallet';
import BadgeDot2 from '../../components/BadgeDot2';
import SizeMenu from './ModalExtraLinks';
import { Alert } from '@mui/joy';



function ColorSchemeToggle() {
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
      setMounted(true);
    }, []);
    if (!mounted) {
      return <IconButton size="sm" variant="outlined" color="primary" />;
    }
    return (
      
      <Button
        id="toggle-mode"
        size="tiny"
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'dark' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </Button>
    );
  }

const TopHeadComp = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
      <>
{drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Navigation />
        </Layout.SideDrawer>
      )}
          <Layout.Header className="header">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1.5,
            }}
          >

              <MenuIcon sx={{ display: { sm: 'none' } }} onClick={() => setDrawerOpen(true)}/>
              <Box sx={{ marginLeft: '0.5rem' }} component="a" href="/" className="LogoBox">
              <svg width="28" height="28" x="0" y="0" viewBox="0 0 64 64" className=""><g><path fill="#ececec" d="M51.24 36.38v4.06c-.02-.17-.05-.33-.1-.48-.19-.71-.61-1.35-1.2-1.85-.83-.69-1.96-1.12-3.21-1.12H23.85c-6.14-.13-11.07-4.37-11.11-9.6v-3.84c0 .16.01.31.03.46.13.88.59 1.66 1.29 2.25.83.7 1.96 1.13 3.21 1.13h24.39c5.1 0 9.26 3.97 9.58 8.99z" data-original="#00faff"></path><path fill="#10b981" d="M51.24 14.51v21.87c-.32-5.02-4.48-8.99-9.58-8.99h-1.53V14.51a5.5 5.5 0 0 1 1.63-3.92 5.5 5.5 0 0 1 3.92-1.63c3.07 0 5.56 2.49 5.56 5.55z" data-original="#009ad3" className=""></path><path fill="#10b981" d="M51.14 39.96v9.6c0 .76-.16 1.48-.43 2.14-.07.14-.14.29-.22.43 0 .02-.01.03-.02.04-.24.41-.53.78-.86 1.11a5.523 5.523 0 0 1-3.93 1.63c-2.65 0-4.86-1.86-5.42-4.35-.01-.06-.02-.11-.02-.17a4.39 4.39 0 0 1-.07-.82l-.03-12.48c0-.03 0-.07.01-.1h6.58c1.25 0 2.38.43 3.21 1.12.59.5 1.01 1.14 1.2 1.85z" data-original="#136c99"></path><path fill="#10b981" d="M23.85 36.99v12.36c0 1.53-.62 2.93-1.63 3.93a5.552 5.552 0 0 1-9.48-3.93V27.39c.04 5.23 4.97 9.47 11.11 9.6z" data-original="#009ad3"></path><path fill="#10b981" d="M23.74 14.56v12.47c0 .12 0 .24-.02.36h-6.45c-1.25 0-2.38-.43-3.21-1.13-.7-.59-1.16-1.37-1.29-2.25l-.02-9.45c0-.97.24-1.88.66-2.67.09-.16.18-.31.28-.46s.2-.3.31-.43c.12-.14.24-.27.36-.4.03-.03.06-.07.1-.1.09-.09.19-.18.29-.26.27-.23.57-.44.89-.61.47-.26.99-.45 1.53-.56.35-.07.71-.11 1.08-.11.19 0 .38.01.56.03.33.03.64.09.94.18.92.26 1.73.76 2.38 1.43 1 1.01 1.61 2.41 1.61 3.96z" data-original="#136c99"></path></g></svg>            
              <svg width="28" height="28" x="0" y="0" viewBox="0 0 64 64" className=""><g><path fill="#ececec" d="M44.81 44.2a5.429 5.429 0 0 1 3.84 9.27c-.98.98-2.34 1.59-3.84 1.59H24.66c1.43 0 2.77 0 4.02-.01v-.07a4.177 4.177 0 0 1-4.11-4.18v-6.6z" data-original="#009ad3"></path><path fill="#10b981" d="M28.68 54.98v.07c-1.25.01-2.59.01-4.02.01h-.22c-.07 0-.15-.01-.22-.01-.32-.02-.63-.05-.94-.09-.18-.03-.37-.06-.56-.09-.18-.03-.36-.07-.54-.11s-.36-.09-.54-.14c-.17-.05-.35-.1-.52-.16-.13-.04-.26-.09-.39-.14-.1-.03-.2-.07-.3-.12-4.07-1.65-6.67-5.53-6.67-10.15V14.34c0-1.49.6-2.84 1.58-3.82s2.33-1.58 3.82-1.58c2.99 0 5.41 2.42 5.41 5.4V50.8c0 2.29 1.83 4.14 4.11 4.18z" data-original="#00faff"></path></g></svg>
 </Box>
          </Box>
 
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>

          <BadgeDot2 />
            <ColorSchemeToggle />
            <WalletButton />
          </Box>
        </Layout.Header>
      </>
    );
  };
  
  export default TopHeadComp;
  