import "../../App.css";
import Sheet from '@mui/joy/Sheet';
import { CircularProgress, LinearProgress, ListItemContent, Stack } from "@mui/joy";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Box from '@mui/joy/Box';
import { Typography } from "@supabase/ui";
import AvatarGroup from '@mui/joy/AvatarGroup';
import Avatar from '@mui/joy/Avatar';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import AspectRatio from '@mui/joy/AspectRatio';
import IconButton from '@mui/joy/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Grid from '@mui/joy/Grid';
import { styled } from '@mui/joy/styles';
import WalletButton from "../../components/ConnectWallet";


// CUSTOM ALERT BOX
import { Alert } from "@supabase/ui";

// Custom Checkbox
import { Checkbox } from "@supabase/ui";


// Custom tabs
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { tabClasses } from '@mui/joy/Tab';

// Custom Badge
import BadgeDot2 from '../../components/BadgeDot2';

// custom Card 
import Link from '@mui/joy/Link';
import Chip from '@mui/joy/Chip';

// custom bottom nav
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';



// custom tabs staking
import { Button } from "@supabase/ui";



import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import "@elastic/eui/dist/eui_theme_dark.css";
import "../../toastify-dark.scss";
import {
  EuiButton,
  EuiProvider,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiPanel,
  EuiCode,
  EuiCodeBlock,
  EuiLoadingSpinner,
  EuiFlexGrid,
  EuiFieldNumber,
  EuiText,
  transparentize,
} from "@elastic/eui";
import { HashConnectTypes, MessageTypes } from "hashconnect";
import { useHashconnectProvider } from "../../hooks/useHashconnectProvider";
import {
  AccountId,
  Client,
  ContractExecuteTransaction,
  ContractCallQuery,
  ContractFunctionParameters,
  ContractFunctionResult,
  Hbar,
  HbarUnit,
  TransactionReceipt,
  TransactionRecord,
  TransactionId,
  TransactionResponse,
  ProxyStaker,
  TokenAssociateTransaction,
} from "@hashgraph/sdk";
import { toast, ToastContainer } from "react-toastify";
import ToastMessage from "../../components/ToastMessage";
import {
  MAINNET_MIRROR_NODE_BASE_URL,
  TOKENS,
  ACCOUNTS_NFTS,
  ACCOUNT_TOKENS,
  IPFS_URL,
  HL_CONTRACT_ID,
  HL_TOKEN_ID,
  HLEG_CONTRACT_ID,
  HLEG_TOKEN_ID,
  HL_CONTRACT_SLOT,
  HLEG_CONTRACT_SLOT,
  BALANCES,
  NOT_AVAILABLE,
  MAX_GAS,
  CONTRACT_STATE,
  TOKENS_NFTS,
} from "../../constants";
import { formatUnicorn } from "../../utils";
import keccak256 from "keccak256";
import Web3EthAbi from "web3-eth-abi";

function Staking() {
  const {
    accountId,
    hashconnect,
    topic,
    pairingString,
    pairingData,
    availableExtension,
    state,
    provider,
    signer,
    initHashconnect,
    connectToExtension,
    sendTransaction,
    requestAccountInfo,
    disconnect,
    clearPairings,
  } = useHashconnectProvider();

  const [isLoading, setIsLoading] = useState("");
  const [HLBalance, setHLBalance] = useState(0);
  const [HLEGBalance, setHLEGBalance] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  // const [balance, setBalance] = useState({
  //   hbar: 0,
  //   hl: 0,
  //   hleg: 0,
  // });
  const [wallet, setWallet] = useState([]);
  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [selectedToStake, setSelectedToStake] = useState([]);
  const [selectedToUnstake, setSelectedToUnstake] = useState([]);
  const [mintAmount, setMintAmount] = useState(1);
  const [isFetching, setIsFetching] = useState(true);
  const [HLInfo, setHLInfo] = useState(null);
  const [HLEGInfo, setHLEGInfo] = useState(null);
  const [HLContractState, setHLContractState] = useState(null);
  const [HLEGContractState, setHLEGContractState] = useState(null);
  const [nowInSeconds, setNowInSeconds] = useState(
    Math.round(Date.now() / 1000)
  );

  const Item = styled(Sheet)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.vars.palette.text.tertiary,
  }));

  const fetchHLInfo = async () => {
    let payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HL_TOKEN_ID)
    ).json();
    setHLInfo(payload);
  };
  const fetchHLEGInfo = async () => {
    const payload = await (
      await fetch(MAINNET_MIRROR_NODE_BASE_URL + TOKENS + HLEG_TOKEN_ID)
    ).json();
    setHLEGInfo(payload);
  };
  const fetchHLContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HL_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HL_CONTRACT_SLOT.COST:
          contractState["cost"] = Number(state.value);
          break;
        case HL_CONTRACT_SLOT.PARTNER:
          contractState["partner"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLContractState(contractState);
  };
  const fetchHLEGContractInfo = async () => {
    const url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: 0,
      limit: 20,
    });
    const payload = await (await fetch(url)).json();
    let contractState = {};
    payload.state.forEach((state) => {
      switch (Number(state.slot)) {
        case HLEG_CONTRACT_SLOT.PERFORMANCE_FEE:
          contractState["fee"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.TOTAL_STAKED:
          contractState["totalStaked"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL:
          contractState["small"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM:
          contractState["medium"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE:
          contractState["large"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.SMALL_REWARD:
          contractState["smallReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.MEDIUM_REWARD:
          contractState["mediumReward"] = Number(state.value);
          break;
        case HLEG_CONTRACT_SLOT.LARGE_REWARD:
          contractState["largeReward"] = Number(state.value);
          break;
        default:
          break;
      }
    });
    setHLEGContractState(contractState);
  };
  const fetchAccountTokens = async () => {
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNT_TOKENS, {
      accountId: accountId,
      tokenId: HLEG_TOKEN_ID,
    });
    const payload = await (await fetch(url)).json();
    if (payload.tokens.length === 0) setHLEGBalance(NOT_AVAILABLE);
    else setHLEGBalance(payload.tokens[0].balance);
  };
  // const fetchAccountBalances = async () => {
  //   let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + BALANCES, {
  //     accountId: accountId,
  //   });
  //   const payload = await (await fetch(url)).json();
  //   let accountBalance = {
  //     hbar: payload.balances[0].balance,
  //     hl: NOT_AVAILABLE,
  //     hleg: NOT_AVAILABLE,
  //   };
  //   payload.balances[0].tokens.forEach((token) => {
  //     switch (token.token_id) {
  //       case HL_TOKEN_ID:
  //         accountBalance.hl = token.balance;
  //         break;
  //       case HLEG_TOKEN_ID:
  //         accountBalance.hleg = token.balance;
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  //   console.log('tokensize :>> ', payload.balances[0].tokens.length);
  //   // console.log("payload :>> ", payload.balances[0].tokens);
  //   setBalance(accountBalance);
  // };
  const fetchAccountNFTs = async () => {
    let nfts = [];
    let isLast = false;
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + ACCOUNTS_NFTS, {
      accountId: accountId,
      tokenId: HL_TOKEN_ID,
      limit: 1,
      gte: 1,
    });
    do {
      const page = await (await fetch(url)).json();
      nfts = [...nfts, ...page.nfts];
      url = MAINNET_MIRROR_NODE_BASE_URL + page.links.next;
      isLast = page.links.next === null;
    } while (!isLast);
    setHLBalance(nfts.length);
    nfts = await Promise.all(
      nfts.map(async (nft) => {
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        return {
          ...nft,
          metadata_json: metadata,
        };
      })
    );
    setWallet(nfts);
    // console.log("nfts :>> ", nfts);
  };

  const fetchStakedNFTs = async () => {
    const mySolAddr =
      "0x" + AccountId.fromString(accountId).toSolidityAddress();
    const second = keccak256(
      Web3EthAbi.encodeParameters(
        ["uint256", "uint256"],
        [mySolAddr, HLEG_CONTRACT_SLOT.STAKE_BY_OWNER.toString()]
      )
    );
    const third = keccak256(second);

    // console.log("mySolAddr :>> ", mySolAddr);
    // console.log("second :>> ", "0x" + second.toString("hex"));
    // console.log("third :>> ", "0x" + third.toString("hex"));
    let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + second.toString("hex"),
      limit: 1,
    });
    // console.log("url :>> ", url);
    const payload = (await (await fetch(url)).json()).state[0];
    const count = Number(
      payload.slot === "0x" + second.toString("hex") ? payload.value : 0
    );
    // console.log("count :>> ", count);
    let pageCount = Math.ceil(count / 100);
    let staked = [];

    url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
      contractId: HLEG_CONTRACT_ID,
      gte: "0x" + third.toString("hex"),
      limit: 100,
    });
    while (pageCount-- > 0) {
      // console.log("url :>> ", url);
      const data = await (await fetch(url)).json();
      const payload = data.state;
      for (let i = 0; i < payload.length; i++) {
        staked.push(Number(payload[i].value));
      }
      url = MAINNET_MIRROR_NODE_BASE_URL + data.links.next;
    }
    staked = staked.slice(0, count);
    setStakedAmount(staked.length);
    staked = await Promise.all(
      staked.map(async (sn) => {
        const nft = await (
          await fetch(
            formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
              tokenId: HL_TOKEN_ID,
              serialNumber: sn,
            })
          )
        ).json();
        const metadata = await (
          await fetch(IPFS_URL + Buffer.from(nft.metadata, "base64").toString())
        ).json();
        const BigInt = window.BigInt;
        let url = formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + CONTRACT_STATE, {
          contractId: HLEG_CONTRACT_ID,
          gte:
            "0x" +
            (
              BigInt(
                "0x" +
                  keccak256(
                    Web3EthAbi.encodeParameters(
                      ["uint256"],
                      [HLEG_CONTRACT_SLOT.STAKE_INFOS.toString()]
                    )
                  ).toString("hex")
              ) +
              BigInt(sn) * BigInt(3) +
              BigInt(1)
            ).toString(16),
          limit: 2,
        });
        // console.log("stakeinfourl :>> ", url);
        const stake = await (await fetch(url)).json();
        const period = Number(stake.state[0].value);
        const timestamp = Number(stake.state[1].value);
        const endAt = timestamp + period * 86400;
        return {
          ...nft,
          metadata_json: metadata,
          stakeInfo: {
            period,
            endAt,
          },
        };
      })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
  };

  const fetchInfos = async () => {
    if (accountId == null) return;
    console.log("fetching data at", Date.now());
    setIsFetching(true);
    await Promise.all([
      fetchHLInfo(),
      fetchHLEGInfo(),
      fetchHLContractInfo(),
      fetchHLEGContractInfo(),
      fetchAccountNFTs(),
      fetchAccountTokens(),
      fetchStakedNFTs(),
      // fetchAccountBalances(),
    ]);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchInfos();
  }, [accountId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchInfos();
      console.log('--------------------------------------------------------------------------------------')
    }, 10000);
    const smallInterval = setInterval(() => {
      (() => {
        setNowInSeconds(Math.round(Date.now() / 1000));
      })();
    }, 500);
    return () => {
      clearInterval(interval);
      clearInterval(smallInterval);
    };
  }, []);

  const onPair = () => {
    if (state === "Paired") {
      disconnect();
      clearPairings();
      window.location.reload();
    } else {
      connectToExtension();
    }
  };
  const callQuery = async (contractId, fn, params) => {
    // const account = pairingData?.accountIds[0];
    const query = new ContractCallQuery()
      .setGas(MAX_GAS)
      .setContractId(HL_CONTRACT_ID)
      .setFunction(fn, params)
      .setQueryPayment(Hbar.fromTinybars(300000));
    // const queryCost = await query.getCost(client);
    let transactionBytes = query.toBytes();

    let res = await sendTransaction(
      transactionBytes,
      accountId,
      false,
      false,
      false
    );
    // console.log("res :>> ", res);
    return res;
  };

  function handleOnChange(e) {
    // use e.target.checked
  }

  const executeContract = async (contractId, fn, params, payableAmount = 0) => {
    let fnResult = null;
    try {
      const trans = new ContractExecuteTransaction()
        .setContractId(contractId)
        .setGas(MAX_GAS) // Increase if revert
        .setPayableAmount(payableAmount) // Increase if revert
        .setFunction(fn, params);
      trans.freezeWithSigner(signer);
      let transactionBytes = trans.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        true
      );
      if (res.success) {
        let arr = new Uint8Array(Object.keys(res.record).length);
        for (let i = 0; i < Object.keys(res.record).length; i++) {
          arr[i] = res.record[i];
        }
        fnResult = new ContractFunctionResult({ bytes: arr });
        // console.log(
        //   `ContractExecuteTransaction ${fn}(${params}) :>> ${fnResult}`
        // );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You should approve to execute the transaction."
          />,
          {
            toastId: fn,
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: fn,
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: fn,
        }
      );
    }
    return fnResult;
  };

  // const getBalance = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("balance");
  //   const txResult = await executeContract(
  //     "balanceOf",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (txResult) {
  //     setNFTBalance(txResult.getUint256(0));
  //   }
  //   setIsLoading("");
  // };

  // const fetchWallet = async () => {
  //   // const account = pairingData?.accountIds[0];
  //   setIsLoading("wallet");
  //   const walletResult = await executeContract(
  //     "walletOfOwner",
  //     new ContractFunctionParameters().addAddress(
  //       AccountId.fromString(account).toSolidityAddress()
  //     )
  //   );
  //   if (walletResult) {
  //     const bal = walletResult.getUint256(1);
  //     setNFTBalance(bal);
  //   }
  //   setIsLoading("");
  // };

  const associate = async () => {
    try {
      const tx = new TokenAssociateTransaction()
        .setAccountId(accountId)
        // .setGas(MAX_GAS) // Increase if revert
        .setTokenIds([HLEG_TOKEN_ID]);
      tx.freezeWithSigner(signer);
      const signedTx = await signer.signTransaction(tx);
      let transactionBytes = signedTx.toBytes();

      const res = await sendTransaction(
        transactionBytes,
        accountId,
        false,
        false,
        false
      );
      // console.log("res :>> ", res);
      if (res.success) {
        toast.success(
          <ToastMessage
            color="success"
            headerText="Success!"
            bodyText="You are now OK to start staking."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error === "USER_REJECT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You should approve to associate $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else if (res.error.status === "TOKEN_ALREADY_ASSOCIATED_TO_ACCOUNT") {
        toast.warn(
          <ToastMessage
            color="warning"
            headerText="Notice!"
            bodyText="You have already associated $HLEG."
          />,
          {
            toastId: "associate",
          }
        );
      } else {
        toast.error(
          <ToastMessage
            color="danger"
            headerText="Error!"
            bodyText="Something went wrong!"
          />,
          {
            toastId: "associate",
          }
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        <ToastMessage color="danger" headerText="Error!" bodyText={err} />,
        {
          toastId: "associate",
        }
      );
    }
    fetchInfos();
  };

  const onAssociate = async () => {
    setIsLoading("associate");
    await associate();
    setIsLoading("");
  };

  const onMint = async () => {
    setIsLoading("mint");
    const txResult = await executeContract(
      HL_CONTRACT_ID,
      "mint",
      new ContractFunctionParameters().addUint256(mintAmount),
      Hbar.from(10 * mintAmount)
    );
    if (txResult) {
      toast.success(
        <ToastMessage
          color="success"
          headerText="Success!"
          bodyText="Successfully minted."
        />,
        {
          toastId: "mint",
        }
      );
      // fetchWallet();
      fetchInfos();
    }
    setIsLoading("");
  };

  const onStake = async (period) => {
    if (HLEGBalance === NOT_AVAILABLE) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="You have to associate $HLEG to claim rewards after staking. First do it and try again."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    if (selectedToStake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "stake",
        }
      );
      return;
    }
    setIsLoading("stake" + period);
    const txResult = await executeContract(
      HLEG_CONTRACT_ID,
      "stake",
      new ContractFunctionParameters()
        .addInt64Array(selectedToStake)
        .addUint256(period),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText="Successfully staked."
      />,
      {
        toastId: "stake",
      }
    );
    // const newNFTs = wallet.filter(
    //   (nft) => selectedToStake.findIndex((sn) => sn === nft.serial_number) >= 0
    // );
    // setWallet(newNFTs);
    setSelectedToStake([]);
    fetchInfos();
    setIsLoading("");
  };

  const onUnstake = async () => {
    if (selectedToUnstake.length === 0) {
      toast.warn(
        <ToastMessage
          color="warn"
          headerText="Alert!"
          bodyText="No NFT selected."
        />,
        {
          toastId: "unstake",
        }
      );
      return;
    }
    setIsLoading("unstake");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "unstake",
      new ContractFunctionParameters().addInt64Array(selectedToUnstake),
      Hbar.fromTinybars(HLEGContractState?.fee)
    );
    const claimed = result.getUint256(0) / Math.pow(10, 10);

    toast.success(
      <ToastMessage
        color="success"
        headerText="Success!"
        bodyText={`You have claimed ${claimed} $HLEG`}
      />,
      {
        toastId: "unstake",
      }
    );
    // const newNFTs = stakedNFTs.filter(
    //   (nft) =>
    //     selectedToUnstake.findIndex((sn) => sn === nft.serial_number) === -1
    // );
    // setWallet(newNFTs);
    setSelectedToUnstake([]);
    fetchInfos();
    setIsLoading("");
  };


  
  const onView = async () => {
    setIsLoading("view");
    const result = await executeContract(
      HLEG_CONTRACT_ID,
      "myStake",
      new ContractFunctionParameters()
    );
    setIsLoading("");

    // const typeSize = result.getUint256(0);
    const count = result.getUint256(1);
    // console.log(`${typeSize}  ${count}`);
    let staked = [];
    for (let i = 0; i < count; i++) {
      const serialNumber = result.getUint160(i * 3 + 2);
      const period = result.getUint256(i * 3 + 3);
      const timestamp = result.getUint256(i * 3 + 4);
      // console.log(
      //   `{\n\t${serialNumber},\n\t${period},\n\t${new Date(
      //     timestamp * 1000
      //   ).toLocaleString()},\n}`
      // );
      staked.push({
        serialNumber,
        period,
        timestamp,
        endAt: Number(timestamp) + Number(period) * 86400,
      });
    }
    staked = await Promise.all(
      staked
        .sort((a, b) => a.serialNumber - b.serialNumber)
        .map(async (stake) => {
          const nft = await (
            await fetch(
              formatUnicorn(MAINNET_MIRROR_NODE_BASE_URL + TOKENS_NFTS, {
                tokenId: HL_TOKEN_ID,
                serialNumber: stake.serialNumber,
              })
            )
          ).json();
          const metadata = await (
            await fetch(
              IPFS_URL + Buffer.from(nft.metadata, "base64").toString()
            )
          ).json();
          return {
            ...nft,
            metadata_json: metadata,
            stakeInfo: stake,
          };
        })
    );
    setStakedNFTs(staked);
    // console.log("staked :>> ", staked);
    setIsLoading("");
  };

  const onAmountChange = (e) => {
    if (e.target.value > 0 && e.target.value <= 10)
      setMintAmount(e.target.value);
  };

  const onStakeCheck = (serialNumber) => {
    let origin = selectedToStake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToStake(origin);
  };

  const onUnstakeCheck = (serialNumber) => {
    let origin = selectedToUnstake;
    let index = origin.findIndex((sn) => sn === serialNumber);
    if (index >= 0) origin.splice(index, 1);
    else origin.push(serialNumber);
    // console.log("new :>> ", origin);
    setSelectedToUnstake(origin);
  };

  useEffect(() => {
    // fetchWallet();
  }, []);

  const [index, setIndex] = React.useState(0);


  return (
    <>

<Box
              variant="outlined"
              sx={{
                borderRadius: 'sm',
                '& > *': {
                  p: 2,
                  '&:nth-child(n):not(:nth-last-child(-n+4))': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  },
                },
              }}
            >
              



<Box className="overflowed">

      <Box>
        <Box className="HOOOFD2">
          
          <ToastContainer />
          <Box className="Hoofd">
            <Box>
              <Box
                
              >

                <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <Box>
<Box
                      paddingSize="s"
                      style={{
                        width: "auto",
                        gap: "50px",
                      }}
                    >
                      {/* <div width="100%">{`Hbar: ${Hbar.fromTinybars(
                        balance.hbar
                      ).toString()}`}</div> */}
                      {/* <div width="100%">{YOUR BALANCE IS ${NFTBalance}}</div> */}



 <Box
className="CardTopGrid"
      row
      sx={{
        gap: 2,
        width: "fit-content",
      }}
    >
      <Box>
        <Typography level="h2" fontSize="lg" id="card-description" >
        {state !== "Paired" ? (

          <Box className="PairBox">
                          <Box>
              <Typography.Text>HL Balance: </Typography.Text>
              </Box>
  <Box>
    <WalletButton />
    </Box>
    <Box>
  <Typography.Text type="success">Wear your Hashpack</Typography.Text>
</Box>
    </Box>
                  ) : (
<>{`My HL's: ${HLBalance}`}</>
)
}
        </Typography>
             

      </Box>
    </Box>

<Alert className="AlertStyle" title="Notice:">Your staked Hedera Legacy NFTs might not appear immediately on this page due to delay in mirror node. However, the boosted utility will be available immediately.</Alert>


    <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ borderRadius: 'lg' }} sx={{
        bgcolor: 'transparent',
        overflowX: 'hidden',
      }}>
      <TabList           sx={{
            width: '100%',
            mx: 'auto',
            bgcolor: 'transparent',
            pt: 2,
            alignSelf: 'flex-start',
            [`& .${tabClasses.root}`]: {
              bgcolor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: 'transparent',
              },
              [`&.${tabClasses.selected}`]: {
                color: '#ececec',
                bgcolor: '#10b981',
                fontWeight: 'lg',
              },
            },
          }}>
                    <Tab>Ready for adventure</Tab>
        <Tab>On adventure</Tab>

      </TabList>




      <TabPanel value={0} sx={{ p: 2 }}>
      {state !== "Paired" ? (
        <Stack spacing={2} >
        <LinearProgress className="colorlinear" />
        </Stack>
                ) : (

<Box className="respoGrid">
{wallet.map((nft, index) => (
<Box className="card2" variant="outlined" key={"token" + index}>
<Box className="FlexBox2">
  
<Box>
<img src={require('../../images/logo-HL.png')} className="max-Imags"/>

</Box>

              <Box sx={{ pt: 0, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }}>
                <Typography.Text>MAINNET HL</Typography.Text>
                  <Typography level="body2" >
    <Checkbox color='primary' label="Check to stake" onChange={handleOnChange} id={"stake" + nft.serial_number}
   checked={
   selectedToStake.findIndex(
   (sn) => sn === nft.serial_number
  ) >= 0
   }
    onChange={(e) =>
     onStakeCheck(nft.serial_number)
   }/>
                  </Typography>
                </Box>
 
              </Box>
   </Box>
   
  </Box>
   ))}                  
 </Box>  

)
}

 <BottomNavigation className="bottomnav"  sx={[
        {
          p: 2,
          gap: '1rem !important',
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          bottom: '10px',

        }]}>
                <Box 
                sx={[{
                  justifyContent: "center",
                  alignItems: "center",
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  }]}
                >
                  {HLEGBalance === NOT_AVAILABLE ? (
                    <Box grow={false}>
                      <Button
                        onClick={onAssociate}
                        size={"small"}
                        disabled={state !== "Paired" || isLoading.length > 0}
                      >
                        {isLoading === "associate" ? (
                          <EuiLoadingSpinner className={"spinner"} />
                        ) : (
                          <>{"ASSOCIATE $HLEG"}</>
                        )}
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* <EuiFlexItem grow={false}>
                    <EuiButton
                      onClick={onView}
                      size={"m"}
                      style={{ width: "300px" }}
                      disabled={state !== "Paired" || isLoading.length > 0}
                    >
                      {isLoading === "view" ? (
                        <EuiLoadingSpinner className={"spinner"} />
                      ) : (
                        <div>{"VIEW MY STAKED NFTS"}</div>
                      )}
                    </EuiButton>
                  </EuiFlexItem> */}

{state !== "Paired" ? (

<Box className="PairBox">
  <Box>
    <WalletButton />
    </Box>
    <Box>
  <Typography.Text type="success">Pair your wallet to get started</Typography.Text>
</Box>
    </Box>

                ) : (
                  <>
                  <Box>
                    <Button type="primary"
                      onClick={() =>
                        onStake(HLEGContractState?.small) ||
                        selectedToStake.length === 0
                      }
                      fill
                      disabled={
                        state !== "Paired" ||
                        isLoading.length > 0 ||
                        selectedToStake.length === 0
                      }
                    >
                      {isLoading === "stake" + HLEGContractState?.small ? (
                        <EuiLoadingSpinner className={"spinner"} />
                      ) : (
                        <div>{` ${HLEGContractState?.small} Days`}</div>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button type="primary"
                      onClick={() => onStake(HLEGContractState?.medium)}
                      fill
                      disabled={
                        state !== "Paired" ||
                        isLoading.length > 0 ||
                        selectedToStake.length === 0
                      }
                    >
                      {isLoading === "stake" + HLEGContractState?.medium ? (
                        <EuiLoadingSpinner className={"spinner"} />
                      ) : (
                        <div>{` ${HLEGContractState?.medium} Days`}</div>
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button type="primary"
                      onClick={() => onStake(HLEGContractState?.large)}
                      fill
                      disabled={
                        state !== "Paired" ||
                        isLoading.length > 0 ||
                        selectedToStake.length === 0
                      }
                    >
                      {isLoading === "stake" + HLEGContractState?.large ? (
                        <EuiLoadingSpinner className={"spinner"} />
                      ) : (
                        <div>{` ${HLEGContractState?.large} Days`}</div>
                      )}
                    </Button>
                  </Box>
                  </>
                )}

                  {/* <EuiFlexItem grow={false}>
                    <EuiButton
                      onClick={onClear}
                      size={"m"}
                      style={{ width: "300px" }}
                    >
                      CLEAR HASHCONNECT DATA
                    </EuiButton>
                  </EuiFlexItem> */}
 
 
                </Box>  
      </BottomNavigation>


      </TabPanel>



      <TabPanel value={1} sx={{ p: 2 }}>
      <Box className="respoGrid">
{stakedNFTs.map((nft, index) => (
<Box className="card3" variant="outlined"  key={"token" + index}>
<Box className="FlexBox2">

<Box>
<img src={require('../../images/logo-HL.png')} className="max-Imags"/>

                              </Box>
                              <Box sx={{ pt: 0, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }}>
                <Typography.Text>MAINNET HL</Typography.Text>
                <Typography.Text level="body2" >
                <Checkbox label="Check to unstake"
                onChange={handleOnChange}
                                id={"stake" + nft.serial_number}
                                checked={
                                  selectedToUnstake.findIndex(
                                    (sn) => sn === nft.serial_number
                                  ) >= 0
                                }
                                onChange={(e) =>
                                  onUnstakeCheck(nft.serial_number)
                                }
                              />
                              </Typography.Text>
                              </Box>
                              </Box>
                            </Box>
                            <Box className="BoxInfo">
                              <Box>
                              <Typography.Text type="success">Staked for: </Typography.Text>
                               <Typography.Text>
                                  {Number(nft.stakeInfo.period) + " Days"}
                                </Typography.Text>
                                </Box>
                                <Box>
                                <Typography.Text type="success">Staking rewards: </Typography.Text>
                                <Typography.Text>
                                  {Number(
                                    Number(nft.stakeInfo.period) ===
                                      HLEGContractState?.small
                                      ? HLEGContractState?.smallReward
                                      : Number(nft.stakeInfo.period) ===
                                        HLEGContractState?.medium
                                      ? HLEGContractState?.mediumReward
                                      : HLEGContractState?.largeReward
                                  ) /
                                    Math.pow(10, 10) +
                                    " $HLEG"}
                                </Typography.Text>
                                </Box>
                                <Box>
                                <Typography.Text type="success">Remaining: </Typography.Text>
                                <Typography.Text>
                                  {nowInSeconds > nft.stakeInfo.endAt
                                    ? "CLAIMABLE"
                                    : `${Math.floor(
                                        (nft.stakeInfo.endAt - nowInSeconds) /
                                          86400
                                      )
                                        .toString()
                                        .padStart(2, "0")} Day(s) ${Math.floor(
                                        ((nft.stakeInfo.endAt - nowInSeconds) %
                                          86400) /
                                          3600
                                      )
                                        .toString()
                                        .padStart(2, "0")}:${Math.floor(
                                        ((nft.stakeInfo.endAt - nowInSeconds) %
                                          3600) /
                                          60
                                      )
                                        .toString()
                                        .padStart(2, "0")}:${Math.floor(
                                        (nft.stakeInfo.endAt - nowInSeconds) %
                                          60
                                      )
                                        .toString()
                                        .padStart(2, "0")}`}
                                </Typography.Text>
                                </Box>
                              </Box>
                          </Box>
                        ))}
</Box>      
 

<BottomNavigation className="bottomnav"  sx={[
        {
          p: 2,
          gap: '1rem !important',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          bottom: '10px',

        }]}>
                <Box 
                sx={[{
                  justifyContent: "center",
                  alignItems: "center",
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  }]}
                >
                  {state !== "Paired" ? (

<Box className="PairBox">
  <Box>
    <WalletButton />
    </Box>
    <Box>
  <Typography.Text type="success">Pair your wallet to get started</Typography.Text>
</Box>
    </Box>

                ) : (
                 <Button type="primary"
                      onClick={() => onUnstake()}
                      fill
                      disabled={
                        state !== "Paired" ||
                        isLoading.length > 0 ||
                        selectedToUnstake.length === 0
                      }
                    >
                      {isLoading === "unstake" ? (
                        <EuiLoadingSpinner className={"spinner"} />
                      ) : (
                        <div>{"Unstake"}</div>
                      )}
                    </Button>
                )}
                  
                </Box>  
      </BottomNavigation>
            </TabPanel>
    </Tabs>




 
                  </Box>
                </Box>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>
      </Box>
 </Box>
 </Box>

    </>
  );
}

export default Staking;
