import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import { Typography } from "@supabase/ui";
import Grid from '@mui/joy/Grid';
import { styled } from '@mui/joy/styles';
import { IconTwitter } from '@supabase/ui'
import { IconGitHub } from '@supabase/ui'
import { Divider, ListItem, ListItemContent, ListSubheader } from '@mui/joy';
import HandshakeIcon from '@mui/icons-material/Handshake';

const Item = styled(Sheet)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.vars.palette.text.tertiary,
  }));


const FooterComponentMain = () => {
    return (
        <>

<Grid container spacing={0} sx={{ flexGrow: 0, bgcolor: 'transparent' }}>

 


<Grid xs={12} md={12}>
  <Item sx={{ bgcolor: 'transparent', textAlign: 'left', border: '1px solid', borderColor: 'divider', textAlign: 'center' }} >
<Box sx={{ paddingTop: '1rem', paddingBottom: '1rem', display: 'inline-flex', gap: '1rem' }}>
  <ListItemContent color='#ececec' component="a" href="https://twitter.com/HederaLegacy">
<IconTwitter />
</ListItemContent>
<ListItemContent color='#ececec'>
<IconGitHub />
</ListItemContent>    
<Divider orientation="vertical" />

<ListItemContent component="a" target="_blank" href="https://6bq9qae8yr2.typeform.com/to/uPRh86hX" color='#ececec' sx={{  alignItems: 'center', display: 'inline-flex' }}>
<HandshakeIcon /> 
        <ListSubheader>
         inquiries
        </ListSubheader>
</ListItemContent>
<Divider orientation="vertical" />
<ListItem nested>
        <ListSubheader>
          v2.0.1
        </ListSubheader>
      </ListItem>
</Box>



    </Item>
</Grid>







</Grid>        


        </>
    );
}

export default FooterComponentMain