import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashBoardPage from "./pages/Dashboard";
import Stake from "./pages/Homepage";
import HomeDAsh from "./pages/HomePageDashBoard";
import Litepaper from "./pages/Litepaper";
import MintingPagesIndexed from "./pages/Minting";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeDAsh />} />
        <Route exact path="/stake" element={<Stake />} />
        <Route exact path="/litepaper" element={<Litepaper />} />
        <Route exact path="/roadmap" element={<DashBoardPage />} />
        <Route exact path="/mint" element={<MintingPagesIndexed />} />

        </Routes>
    </BrowserRouter>
  );
}
//
export default App;
